<div class="card">
  <p class="card-title">{{ "device.adder.title" | translate }}</p>
  <p class="add-description">
    {{ "device.adder.hint" | translate }}
  </p>
  <form [formGroup]="addFormGroup" class="form">
    <div>
      <input
        id="mac"
        type="text"
        formControlName="mac"
        [placeholder]="'device.adder.placeholder' | translate"
        (keydown.enter)="addDevice()"
        maxlength="17"
        (keyup)="allUpperCase($event)"
      />
      <span
        *ngIf="
          (!addFormGroup.controls.mac.valid &&
            addFormGroup.controls.mac.touched) ||
          adoptionFailed
        "
        class="red"
      >
        {{ errorMessage }}
      </span>
      <p
        *ngIf="!addFormGroup.controls.mac.touched && adoptionSucceeded"
        class="green"
      >
        {{ "device.adder.success" | translate }}
      </p>
    </div>
    <input
      type="submit"
      value="{{ 'device.adder.submit' | translate }}"
      (click)="addDevice()"
      style="margin-top: 10px"
    />
  </form>
</div>
