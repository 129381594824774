import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BrowserService {
  device: any;

  constructor() {}

  isCordova(): boolean {
    return navigator.userAgent.toLowerCase().indexOf('wv') > -1;
  }

  isAndroid(): boolean {
    return navigator.userAgent.toLowerCase().indexOf('android') > -1;
  }
}
