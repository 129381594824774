import { Component, OnInit } from '@angular/core';
import { Device } from '../../models/Device';
import { DeviceService } from '../../services/device.service';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss'],
})
export class DevicesComponent implements OnInit {
  devices: Device[] = [];

  constructor(private deviceService: DeviceService) {
    this.deviceService.devicesSubject.subscribe((devices) => {
      this.devices = devices;
    });
  }

  ngOnInit(): void {
    this.deviceService.getDevices();
  }
}
