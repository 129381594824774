import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import Chart from 'chart.js/auto';
import { Device } from '../../models/Device';
import 'chartjs-adapter-moment';
import { DeviceService } from '../../services/device.service';
import annotationPlugin from 'chartjs-plugin-annotation';
import { TranslateService } from '@ngx-translate/core';

Chart.register(annotationPlugin);

@Component({
  selector: 'app-moisture-chart',
  templateUrl: './moisture-chart.component.html',
  styleUrls: ['./moisture-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MoistureChartComponent implements OnInit {
  @Input() device: Device | undefined;
  public chart: any;
  rendered = false;
  notEnoughData = false;

  constructor(
    private deviceService: DeviceService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.deviceService.deviceDetailsSubject.subscribe((device) => {
      if (device) {
        this.device = device;
        this.createChart();
      }
    });
  }

  createChart() {
    if (
      !this.device ||
      !this.device.datapoints ||
      this.device.datapoints.length < 2
    ) {
      if (
        this.device &&
        this.device.datapoints &&
        this.device.datapoints.length < 2
      ) {
        this.notEnoughData = true;
      }
      return;
    }

    const labels: any[] = [];
    const moistureData: any[] = [];
    const wateringData: any[] = [];

    let lastTotalWateringAmount = 0;
    this.device.datapoints = this.device.datapoints.sort((a, b) => {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    });

    this.device.datapoints.forEach((datapoint) => {
      datapoint.createdAt = new Date(datapoint.createdAt);
      if (
        datapoint.createdAt.getTime() <
        new Date().getTime() - 1000 * 60 * 60 * 24 * 14
      ) {
        return;
      }
      datapoint.data.moisture = datapoint.data.moisture || 0;
      datapoint.data.totalWateringAmount =
        datapoint.data.totalWateringAmount || 0;
      let totalWateringAmountDelta =
        datapoint.data.totalWateringAmount - lastTotalWateringAmount;
      lastTotalWateringAmount = datapoint.data.totalWateringAmount;

      labels.push(datapoint.createdAt);
      wateringData.push(totalWateringAmountDelta);
      moistureData.push(datapoint.data.moisture);
    });

    wateringData.shift();
    wateringData.push(0);

    if (labels.length < 2) {
      return;
    }
    if (!this.chart) {
      this.chart = new Chart('MyChart', {
        data: {
          labels: labels,
          datasets: [
            {
              label: this.translate.instant('device.moistureChart.wateramount'),
              data: wateringData,
              backgroundColor: '#6c8dc8',
              borderColor: '#6c8dc8',
              yAxisID: 'watering',
              barThickness: 1,
              type: 'bar',
              order: 0,
            },
            {
              label: this.translate.instant('device.moistureChart.moisture'),
              data: moistureData,
              backgroundColor: '#729564',
              borderColor: '#729564',
              yAxisID: 'moisture',
              pointStyle: false,
              showLine: true,
              type: 'line',
              order: 1,
            },
          ],
        },
        options: {
          hover: {
            mode: 'index',
            intersect: false,
          },
          plugins: {
            tooltip: {
              mode: 'index',
              intersect: false,
              position: 'nearest',
            },
            title: {
              display: true,
              text: this.translate.instant('device.moistureChart.title'),
              font: {
                size: 20,
              },
            },
            annotation: {
              annotations: {
                threshold: {
                  type: 'line',
                  yMin: this.device.config.wetThreshold,
                  yMax: this.device.config.wetThreshold,
                  borderColor: 'rgb(255, 99, 132)',
                  borderWidth: 2,
                  label: {
                    content: this.translate.instant(
                      'device.moistureChart.threshold',
                    ),
                    display: true,
                    color: 'rgb(255, 99, 132)',
                    backgroundShadowColor: 'rgba(0, 0, 0, 0)',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    yAdjust: 10,
                    xAdjust: -10,
                    position: 'start',
                    borderWidth: 0,
                    font: {
                      size: 10,
                    },
                  },
                },
              },
            },
          },
          responsive: true,
          aspectRatio: 1.25,
          animation: false,
          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'day',
                displayFormats: {
                  day: 'DD.MM.',
                },
                tooltipFormat: 'DD.MM.YYYY HH:mm',
              },
              suggestedMin: new Date().getTime() - 1000 * 60 * 60 * 24 * 7,
              suggestedMax: new Date().getTime(),
            },
            moisture: {
              title: {
                display: true,
                text: this.translate.instant('device.moistureChart.moisture'),
              },
              type: 'linear',
              reverse: true,
              suggestedMin: 800,
              suggestedMax: 1800,
            },
            watering: {
              title: {
                display: true,
                text: this.translate.instant(
                  'device.moistureChart.wateramountScale',
                ),
              },
              position: 'right',
              grid: {
                drawOnChartArea: false,
              },
              suggestedMax: 2,
              suggestedMin: 0,
            },
          },
        },
      });
    } else {
      this.chart.data.labels = labels;
      this.chart.data.datasets[0].data = wateringData;
      this.chart.data.datasets[1].data = moistureData;
      this.chart.update();
    }
    this.rendered = true;
  }
}
