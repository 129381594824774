import { Component, OnInit } from '@angular/core';
import { Device } from '../../models/Device';
import { DeviceService } from '../../services/device.service';
import { Router } from '@angular/router';
import { DeviceUserConfig } from '../../models/DeviceUserConfig';

@Component({
  selector: 'app-device-details',
  templateUrl: './device-details.component.html',
  styleUrls: ['./device-details.component.scss'],
})
export class DeviceDetailsComponent implements OnInit {
  device: Device = new Device('', '', new DeviceUserConfig());
  deviceNotFound = false;
  twelveHoursAgo = new Date(new Date().getTime() - 1000 * 60 * 60 * 12);
  inOneDay = new Date(new Date().getTime() + 1000 * 60 * 60 * 24);
  lastWatering = new Date(0);
  expectedNextWatering = new Date(0);
  isWet = false;
  twentyten = new Date(2010, 1, 1);
  protected readonly Date = Date;

  constructor(
    private deviceService: DeviceService,
    private router: Router,
  ) {
    this.deviceService.deviceDetailsSubject.subscribe((device) => {
      if (device) {
        this.device = device;
        this.device.lastSeen = new Date(this.device.lastSeen);
        if (this.device.datapoints) {
          this.device.datapoints = this.device.datapoints.sort((a, b) => {
            return (
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
            );
          });
        }
        this.calculateWateringTimes();
      }
    });
    this.deviceService.deviceNotFoundSubject.subscribe((deviceNotFound) => {
      this.deviceNotFound = deviceNotFound;
      if (this.deviceNotFound) {
        this.router.navigate(['/']);
      }
    });
    setInterval(
      () => {
        this.deviceService.getDevice(this.device.mac);
        this.twelveHoursAgo = new Date(
          new Date().getTime() - 1000 * 60 * 60 * 12,
        );
        this.inOneDay = new Date(new Date().getTime() + 1000 * 60 * 60 * 24);
      },
      3 * 60 * 1000,
    );
  }

  ngOnInit(): void {
    const mac = this.router.url.split('/')[2];
    this.deviceService.getDevice(mac);
  }

  calculateWateringTimes() {
    this.lastWatering = new Date(0);
    if (
      this.device.datapoints &&
      this.device.datapoints.length > 0 &&
      this.device.datapoints[this.device.datapoints.length - 1].data !==
        undefined
    ) {
      if (
        this.device.datapoints[this.device.datapoints.length - 1].data
          .lastWateringTime !== undefined
      ) {
        this.lastWatering = new Date(
          <any>(
            this.device.datapoints[this.device.datapoints.length - 1].data
              .lastWateringTime
          ) * 1000,
        );
        this.expectedNextWatering = new Date(
          this.lastWatering.getTime() +
            this.device.config.wateringInterval * 1000,
        );
      }
      if (
        this.device.datapoints[this.device.datapoints.length - 1].data
          .moisture !== undefined
      ) {
        this.isWet =
          (this.device.datapoints[this.device.datapoints.length - 1].data
            .moisture ?? 10000) < this.device.config.wetThreshold;
      }
    }
  }
}
