import { Component, OnInit } from '@angular/core';
import { BrowserService } from '../../services/browser.service';

@Component({
  selector: 'app-download-banner',
  templateUrl: './download-banner.component.html',
  styleUrls: ['./download-banner.component.scss'],
})
export class DownloadBannerComponent implements OnInit {
  showBanner = false;

  constructor(private browserService: BrowserService) {}

  ngOnInit(): void {
    this.calculateShowBanner();
    setInterval(
      () => {
        this.calculateShowBanner();
      },
      1000 * 60 * 60,
    );
  }

  calculateShowBanner() {
    let isAndroid = this.browserService.isAndroid();
    const isCordova = this.browserService.isCordova();
    const lastRejection = localStorage.getItem('lastRejection');
    const oneWeekAgo = new Date(Date.now() - 86400 * 7 * 1000);
    if (
      isAndroid &&
      !isCordova &&
      (!lastRejection || new Date(lastRejection) < oneWeekAgo)
    ) {
      this.showBanner = true;
    }
  }

  hideBanner() {
    this.showBanner = false;
    localStorage.setItem('lastRejection', new Date().toISOString());
  }
}
