import { Component, Input, OnInit } from '@angular/core';
import { Device } from '../../models/Device';
import { Router } from '@angular/router';
import { DeviceService } from '../../services/device.service';

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss'],
})
export class DeviceComponent implements OnInit {
  @Input() device!: Device;
  twelveHoursAgo = new Date(new Date().getTime() - 1000 * 60 * 60 * 12);

  constructor(
    private router: Router,
    private deviceService: DeviceService,
  ) {}

  ngOnInit(): void {
    this.device.lastSeen = new Date(this.device.lastSeen);
    setInterval(() => {
      this.twelveHoursAgo = new Date(
        new Date().getTime() - 1000 * 60 * 60 * 12,
      );
    }, 60 * 1000);
  }

  editDevice(device: Device) {
    this.deviceService.hideDevice();
    this.router.navigate(['/devices', device.mac]).then();
  }
}
