<div class="card download-banner" *ngIf="showBanner">
  <button class="card-cross" (click)="hideBanner()">X</button>
  <p class="card-title">{{ "download-banner.title" | translate }}</p>
  <p class="card-text">
    {{ "download-banner.text" | translate }}
    <a
      href="https://play.google.com/store/apps/details?id=ch.plantguardian.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
      ><img
        alt="Jetzt bei Google Play"
        class="store-badge"
        ngSrc="/assets/images/play_badge.png"
        height="250"
        width="646"
    /></a>
  </p>
</div>
