export enum PotSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum MoistureThreshold {
  VERY_DRY = 'very_dry',
  DRY = 'dry',
  NORMAL = 'normal',
  WET = 'wet',
  VERY_WET = 'very_wet',
}

export class DeviceUserConfig {
  potSize: PotSize;
  moistureThreshold: MoistureThreshold;

  constructor(
    potSize: PotSize = PotSize.MEDIUM,
    moistureThreshold: MoistureThreshold = MoistureThreshold.NORMAL,
  ) {
    this.potSize = potSize;
    this.moistureThreshold = moistureThreshold;
  }
}
