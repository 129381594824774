<img
  [ngSrc]="logoPath"
  alt="logo"
  class="logo"
  width="500"
  height="500"
  priority
/>
<div id="landing-page" #landingPage style="display: block">
  <h1 class="title">{{ 'login.title' | translate }}</h1>
  <p class="subtitle">{{ 'login.subtitle' | translate }}</p>
  <div class="button-group">
    <button class="button" (click)="login()" autofocus>{{ 'login.login' | translate }}</button>
  </div>
</div>
<div id="mail-input" #mailInput style="display: none">
  <h1 class="title">{{ 'login.title' | translate }}</h1>
  <form [formGroup]="mailForm" class="form form-center">
    <label for="mail" class="subtitle"
    >{{ 'login.mailLabel' | translate }}</label
    >
    <input
      type="email"
      [placeholder]="'login.mailPlaceholder' | translate"
      class="input"
      formControlName="mail"
      (keydown.enter)="sendCode()"
      id="mail"
      #mailInputField
    />
    <label
      class="red"
      *ngIf="
        !mailForm.controls['mail'].valid && mailForm.controls['mail'].touched
      "
    >{{ 'login.mailError' | translate }}</label
    >
  </form>
  <div class="button-group">
    <button class="button" (click)="landing()">{{ 'login.back' | translate }}</button>
    <button class="button" (click)="sendCode()">{{ 'login.next' | translate }}</button>
  </div>
</div>
<div id="code-input" #codeInput style="display: none">
  <h1 class="title">{{ 'login.title' | translate }}</h1>
  <form [formGroup]="codeForm" class="form form-center">
    <label for="code" class="subtitle"
    >{{ 'login.codeLabel' | translate }}</label>
    <input
      type="text"
      placeholder="Code"
      class="input"
      formControlName="code"
      maxlength="6"
      pattern="[0-9A-Z]*"
      (input)="onCodeInput($event)"
      (keydown.enter)="confirmCode()"
      id="code"
      #codeInputField
    />
    <label
      class="red"
      *ngIf="
        (!codeForm.controls['code'].valid &&
          codeForm.controls['code'].touched) ||
        wrongCode
      "
    >{{ 'login.codeError' | translate }}</label
    >
  </form>
  <div class="button-group">
    <button class="button" (click)="login()">{{ 'login.back' | translate }}</button>
    <button class="button" (click)="confirmCode()">{{ 'login.next' | translate }}</button>
  </div>
</div>
