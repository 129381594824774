import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, catchError} from 'rxjs';
import {environment} from '../../environments/environment';
import {User} from '../models/User';
import {Router} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root',
})
export class UserService {
  user: User | null = null;
  userSubject = new BehaviorSubject<any>(null);

  loggedIn = false;
  loggedInSubject = new BehaviorSubject<boolean>(false);

  wrongCode = false;
  wrongCodeSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private router: Router,
    private translate: TranslateService,
  ) {}

  getSalt() {
    let salt = localStorage.getItem('salt');
    let expired = false;
    if (salt) {
      const saltDate = new Date(parseInt(salt.split('_')[1], 10));
      if (new Date().getTime() - saltDate.getTime() > 1000 * 60 * 60 * 24) {
        expired = true;
      }
      salt = salt.split('_')[0];
    }
    if (!salt || expired) {
      let chars =
        'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      salt = '';
      for (let i = 0; i < 32; i++) {
        salt += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      localStorage.setItem('salt', salt + '_' + new Date().getTime());
    }
    return salt;
  }

  sendCode(mail: string) {
    this.http
      .post(environment.apiBaseUrl + '/user/', { mail, salt: this.getSalt() })
      .subscribe((response) => {});
  }

  confirmCode(mail: string, code: string) {
    this.http
      .post(environment.apiBaseUrl + '/user/', {
        mail,
        code,
        salt: this.getSalt(),
      })
      .pipe(
        catchError((err) => {
          this.wrongCode = true;
          this.wrongCodeSubject.next(true);
          return err;
        }),
      )
      .subscribe((response: any) => {
        this.wrongCode = false;
        this.wrongCodeSubject.next(false);
        const token = response['token'];
        localStorage.setItem('token', token);
        this.getUser();
      });
  }

  getUser() {
    this.http
      .get<User>(environment.apiBaseUrl + '/user/')
      .subscribe((response) => {
        this.user = response;
        this.userSubject.next(response);
        this.loggedIn = true;
        this.loggedInSubject.next(true);
        // if on login page, redirect to home
        if (this.router.url === '/login') {
          this.router.navigate(['/']).then();
        }
      });
  }

  logout() {
    this.http
      .post(environment.apiBaseUrl + '/user/logout', {})
      .subscribe(() => {
        localStorage.removeItem('token');
        this.loggedIn = false;
        this.loggedInSubject.next(false);
        this.router.navigate(['/login']).then();
      });
  }
}
