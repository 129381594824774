import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { LogoService } from '../../services/logo.service';

@Component({
  selector: 'app-landing',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @ViewChild('landingPage') landingPage: ElementRef | undefined;
  @ViewChild('mailInput') mailInput: ElementRef | undefined;
  @ViewChild('mailInputField') mailInputField: ElementRef | undefined;
  @ViewChild('codeInput') codeInput: ElementRef | undefined;
  @ViewChild('codeInputField') codeInputField: ElementRef | undefined;
  codeForm: FormGroup;
  mailForm: FormGroup;
  loggedIn = false;
  wrongCode = false;
  logoPath: string;

  constructor(
    private userService: UserService,
    private router: Router,
    private logoService: LogoService,
  ) {
    this.codeForm = new FormGroup({
      code: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6),
      ]),
    });
    this.mailForm = new FormGroup({
      mail: new FormControl('', [Validators.required, Validators.email]),
    });

    this.logoPath = logoService.getLogoPath();
    setInterval(() => {
      this.logoPath = logoService.getLogoPath();
    }, 60 * 1000);
  }

  ngOnInit(): void {
    this.userService.loggedInSubject.subscribe(async (loggedIn) => {
      this.loggedIn = loggedIn;
      if (loggedIn) {
        await this.router.navigate(['/']);
      }
    });
    this.userService.wrongCodeSubject.subscribe((wrongCode) => {
      this.wrongCode = wrongCode;
    });
  }

  login() {
    if (this.landingPage && this.mailInput && this.codeInput) {
      this.landingPage.nativeElement.style.display = 'none';
      this.codeInput.nativeElement.style.display = 'none';
      this.mailInput.nativeElement.style.display = 'block';
    }
    this.mailInputField?.nativeElement.focus();
  }

  sendCode() {
    this.mailForm.markAllAsTouched();
    const mail = this.mailForm.get('mail')?.value;
    if (!this.mailForm.valid || !mail) {
      return;
    }
    this.userService.sendCode(mail);

    if (this.mailInput && this.codeInput && this.codeInputField) {
      this.mailInput.nativeElement.style.display = 'none';
      this.mailInput.nativeElement.style.display = 'none';
      this.codeInput.nativeElement.style.display = 'block';
    }
    this.codeInputField?.nativeElement.focus();
  }

  allUpperCase($event: Event) {
    const target = $event.target as HTMLInputElement;
    target.value = target.value.toUpperCase();
  }

  onCodeInput($event: Event) {
    this.allUpperCase($event);
    this.codeForm.setErrors(null);
    this.wrongCode = false;
  }

  confirmCode() {
    this.codeForm.markAllAsTouched();
    const code = this.codeForm.get('code')?.value;
    const mail = this.mailForm.get('mail')?.value;
    const valid = code.length === 6;
    if (!code || !mail || !valid) {
      return;
    }
    this.userService.confirmCode(mail, code.toUpperCase());
  }

  landing() {
    if (this.landingPage && this.mailInput && this.codeInput) {
      this.landingPage.nativeElement.style.display = 'block';
      this.codeInput.nativeElement.style.display = 'none';
      this.mailInput.nativeElement.style.display = 'none';
    }
  }
}
