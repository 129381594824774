import { Component, Input, OnInit } from '@angular/core';
import { Device } from '../../models/Device';
import { PotSize } from '../../models/DeviceUserConfig';
import { DeviceService } from '../../services/device.service';

@Component({
  selector: 'app-potsize',
  templateUrl: './potsize.component.html',
  styleUrls: ['./potsize.component.scss'],
})
export class PotsizeComponent implements OnInit {
  @Input() device: Device | undefined;
  potSize = PotSize.MEDIUM;
  protected readonly PotSize = PotSize;

  constructor(private deviceService: DeviceService) {}

  ngOnInit(): void {
    setInterval(() => {
      if (this.device && this.device.userConfig) {
        this.potSize = this.device.userConfig.potSize || PotSize.MEDIUM;
      }
    }, 500);
  }

  onPotSizeChange(potSize: PotSize) {
    if (this.device) {
      this.deviceService.setPotSize(this.device.mac, potSize);
    }
  }
}
