<div class="device-details">
  <app-banner></app-banner>
  <h2>{{ "device.details" | translate }}</h2>
  <div *ngIf="!deviceNotFound">
    <h3>
      {{ device.description ? device.description : device.name }}
      <span *ngIf="device.lastSeen < twelveHoursAgo" class="red">{{
        "device.offline" | translate
      }}</span>
    </h3>
    <app-moisture-chart [device]="device"></app-moisture-chart>
    <br />
    <table>
      <tr
        *ngIf="
          lastWatering.getTime() !== 0 &&
          lastWatering.getTime() > twentyten.getTime()
        "
      >
        <td>{{ "device.lastWatering" | translate }}</td>
        <td>{{ lastWatering | date: "dd.MM.yyyy" }}</td>
      </tr>
      <tr *ngIf="expectedNextWatering">
        <td>{{ "device.nextWatering" | translate }}</td>
        <td *ngIf="device.lastSeen < twelveHoursAgo" class="red">
          {{ "device.deviceOffline" | translate }}
        </td>
        <td
          *ngIf="
            expectedNextWatering < inOneDay &&
            device.lastSeen > twelveHoursAgo &&
            !isWet
          "
          class="green"
        >
          &lt;24h
        </td>
        <td
          *ngIf="
            expectedNextWatering > inOneDay &&
            device.lastSeen > twelveHoursAgo &&
            !isWet
          "
        >
          {{ expectedNextWatering | date: "dd.MM.yyyy" }}
        </td>
        <td
          *ngIf="isWet && device.lastSeen > twelveHoursAgo"
          class="green-normal"
        >
          {{ "device.wateringNotNecessary" | translate }}
        </td>
      </tr>
    </table>
    <app-potsize [device]="device"></app-potsize>
    <app-moisture-threshold [device]="device"></app-moisture-threshold>
    <app-water-now [device]="device"></app-water-now>
  </div>
  <div *ngIf="deviceNotFound">
    <h3>{{ "device.notFound" | translate }}</h3>
  </div>
</div>
