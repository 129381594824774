import { Injectable } from '@angular/core';
import { Device } from '../models/Device';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, catchError, of } from 'rxjs';
import { MoistureThreshold, PotSize } from '../models/DeviceUserConfig';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  devices: Device[] = [];
  devicesSubject = new BehaviorSubject<Device[]>([]);

  adoptionFailed = false;
  adoptionFailedSubject = new BehaviorSubject<boolean>(false);

  adoptionSucceeded = false;
  adoptionSucceededSubject = new BehaviorSubject<boolean>(false);

  adoptionError = '';
  adoptionErrorSubject = new BehaviorSubject<string>('');

  deviceDetails: Device | null = null;
  deviceDetailsSubject = new BehaviorSubject<Device | null>(null);

  deviceNotFound = false;
  deviceNotFoundSubject = new BehaviorSubject<boolean>(false);

  waterNowSuccess = false;
  waterNowSuccessSubject = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
    setInterval(
      () => {
        this.getDevices();
      },
      3 * 60 * 1000,
    );
  }

  getDevices(): void {
    this.http
      .get<Device[]>(environment.apiBaseUrl + '/devices')
      .subscribe((devices) => {
        this.devices = devices;
        this.devicesSubject.next(devices);
      });
  }

  adoptDevice(mac: string): void {
    this.http
      .post(environment.apiBaseUrl + '/devices/' + mac + '/adopt', {})
      .pipe(
        catchError((err) => {
          this.adoptionError = err.error.message;
          this.adoptionErrorSubject.next(err.error.message);
          this.adoptionFailed = true;
          this.adoptionFailedSubject.next(true);
          this.adoptionSucceeded = false;
          this.adoptionSucceededSubject.next(false);
          return of(err);
        }),
      )
      .subscribe((data: any) => {
        if (data.message !== 'ok') {
          return;
        }
        this.adoptionFailed = false;
        this.adoptionFailedSubject.next(false);
        this.adoptionSucceeded = true;
        this.adoptionSucceededSubject.next(true);
        this.getDevices();
      });
  }

  getDevice(mac: string): void {
    this.http
      .get<Device>(environment.apiBaseUrl + '/devices/' + mac)
      .pipe(
        catchError((err: any) => {
          let isInvalidMac = false;
          if (
            err.status === 400 &&
            err.error.errors.find(
              (e: any) =>
                e.path === '/params/mac' &&
                e.errorCode === 'pattern.openapi.validation',
            )
          ) {
            isInvalidMac = true;
          }
          if (err.status === 404 || isInvalidMac) {
            this.deviceNotFound = true;
            this.deviceNotFoundSubject.next(true);
            this.deviceDetails = null;
            this.deviceDetailsSubject.next(null);
          }
          return of(err);
        }),
      )
      .subscribe((device) => {
        this.deviceNotFound = false;
        this.deviceNotFoundSubject.next(false);
        this.deviceDetails = device as Device;
        this.deviceDetailsSubject.next(device as Device);
      });
  }

  hideDevice(): void {
    this.deviceNotFound = false;
    this.deviceNotFoundSubject.next(false);
    this.deviceDetails = null;
    this.deviceDetailsSubject.next(null);
  }

  setPotSize(mac: string, potSize: PotSize): void {
    this.http
      .patch<Device>(environment.apiBaseUrl + '/devices/' + mac + '/config', {
        potSize,
      })
      .subscribe((device) => {
        this.getDevice(mac);
      });
  }

  setMoistureThreshold(
    mac: string,
    moistureThreshold: MoistureThreshold,
  ): void {
    this.http
      .patch<Device>(environment.apiBaseUrl + '/devices/' + mac + '/config', {
        moistureThreshold,
      })
      .subscribe((device) => {
        this.getDevice(mac);
      });
  }

  waterNow(mac: string): void {
    this.http
      .post(environment.apiBaseUrl + '/devices/' + mac + '/water', {})
      .subscribe((data: any) => {
        if (data.message !== 'ok') {
          return;
        }
        this.getDevice(mac);
        this.waterNowSuccess = true;
        this.waterNowSuccessSubject.next(true);
        setTimeout(() => {
          this.waterNowSuccess = false;
          this.waterNowSuccessSubject.next(false);
        }, 10000);
      });
  }
}
