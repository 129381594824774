import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DeviceService } from '../../services/device.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-device-adder',
  templateUrl: './device-adder.component.html',
  styleUrls: ['./device-adder.component.scss'],
})
export class DeviceAdderComponent implements OnInit {
  addFormGroup = new FormGroup({
    mac: new FormControl('', [Validators.required]),
  });
  adoptionFailed = false;
  adoptionSucceeded = false;
  errorMessage = this.translate.instant('device.adder.error.generic');
  lastSubmitted = 0;

  constructor(
    private deviceService: DeviceService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.deviceService.adoptionFailedSubject.subscribe((failed) => {
      this.adoptionFailed = failed;
    });
    this.deviceService.adoptionSucceededSubject.subscribe((succeeded) => {
      this.adoptionSucceeded = succeeded;
      if (succeeded) {
        this.addFormGroup.reset();
      }
    });
    this.deviceService.adoptionErrorSubject.subscribe((error) => {
      if (
        error.startsWith('request/params/mac must match pattern') ||
        error.startsWith('Device cannot be adopted, because it does not exist')
      ) {
        this.errorMessage = this.translate.instant(
          'device.adder.error.idInvalid',
        );
        return;
      }
      if (
        error.startsWith(
          'Device cannot be adopted, because it is already adopted',
        )
      ) {
        this.errorMessage = this.translate.instant(
          'device.adder.error.alreadyAdopted',
        );
        return;
      }
      if (error.startsWith('Device cannot be adopted, because it is offline')) {
        this.errorMessage = this.translate.instant(
          'device.adder.error.offline',
        );
        return;
      }
      this.errorMessage = error;
    });
  }

  addDevice(): void {
    this.addFormGroup.markAllAsTouched();
    let mac = this.addFormGroup.get('mac')?.value;
    if (!mac) {
      return;
    }

    if (Date.now() - this.lastSubmitted < 2000) {
      return;
    }
    this.lastSubmitted = Date.now();

    mac = mac.trim();
    mac = mac.toUpperCase();
    mac = mac.replace(/[^0-9A-Z]/g, '');
    mac = mac.replace(/(.{2})/g, '$1:');
    mac = mac.slice(0, -1);
    if (mac.length === 0) {
      mac = '00:00:00:00:00:00';
    }
    this.deviceService.adoptDevice(mac);
  }

  allUpperCase($event: KeyboardEvent): void {
    const input = $event.target as HTMLInputElement;
    input.value = input.value.toUpperCase();
  }
}
