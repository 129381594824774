<h1>{{ 'device.waterNow.title' | translate }}</h1>
<p *ngIf="!waterNowPossible && !isWateringNow" class="red-normal">
  {{ 'device.waterNow.stillWet' | translate }}
</p>
<p *ngIf="isWateringNow && !offline" class="green-normal">
  {{ 'device.waterNow.wateringNow' | translate }}
</p>
<p *ngIf="offline" class="red-normal">
  {{ 'device.waterNow.deviceOffline' | translate }}
</p>
<button
  (click)="onWater()"
  class="button"
  style="margin-top: 10px; align-self: center; width: 80%; margin-inline: 10%"
  [disabled]="!waterNowPossible || waterNowSuccess || isWateringNow || offline"
>
  {{ 'device.waterNow.waterNow' | translate }}
</button>
<p *ngIf="waterNowSuccess" class="green-normal">
  {{ 'device.waterNow.wateringSuccess' | translate }}
</p>
