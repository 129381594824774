import { Component, Input, OnInit } from '@angular/core';
import { Device } from '../../models/Device';
import { MoistureThreshold } from '../../models/DeviceUserConfig';
import { DeviceService } from '../../services/device.service';

@Component({
  selector: 'app-moisture-threshold',
  templateUrl: './moisture-threshold.component.html',
  styleUrls: ['./moisture-threshold.component.scss'],
})
export class MoistureThresholdComponent implements OnInit {
  @Input() device: Device | undefined;
  moistureThreshold = MoistureThreshold.NORMAL;

  constructor(private deviceService: DeviceService) {
    setInterval(() => {
      if (this.device && this.device.userConfig) {
        this.moistureThreshold =
          this.device.userConfig.moistureThreshold || MoistureThreshold.NORMAL;
      }
    }, 500);
  }

  ngOnInit(): void {}

  onMoistureThresholdChange(newThreshold: MoistureThreshold) {
    if (this.device) {
      this.deviceService.setMoistureThreshold(this.device.mac, newThreshold);
    }
  }

  protected readonly MoistureThreshold = MoistureThreshold;
}
