<h1 class="title">{{ 'noConnection.title' | translate }}</h1>
<p class="text">
  {{ 'noConnection.text' | translate }}
</p>
<iframe
  src="https://status.plantguardian.ch"
  onLoad="this.style.opacity = 1"
  class="status"
  style="opacity: 0; transition: opacity 1s ease-in-out"
></iframe>
