<div class="chart-container">
  <canvas *ngIf="!notEnoughData" id="MyChart">{{ chart }}</canvas>
  <details class="explanation">
    <summary>{{ 'device.moistureChartExplanationSummary' | translate }}</summary>
    <div [innerHTML]="'device.moistureChartExplanation' | translate">
    </div>
  </details>
  <div *ngIf="notEnoughData" class="no-data">
    <p>{{ 'device.moistureChartNoData' | translate }}</p>
  </div>
</div>
