import { Datapoint } from './Datapoint';
import { DeviceUserConfig } from './DeviceUserConfig';
import { DeviceConfig } from './DeviceConfig';

export class Device {
  mac: string;
  name: string;
  description: string;
  lastSeen: Date;
  datapoints: Datapoint[];
  userConfig: DeviceUserConfig;
  config: DeviceConfig;

  constructor(mac: string, name: string, userConfig: DeviceUserConfig) {
    this.mac = mac;
    this.name = name;
    this.description = '';
    this.lastSeen = new Date();
    this.datapoints = [];
    this.userConfig = userConfig;
    this.config = new DeviceConfig();
  }
}
