import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SessionService} from '../services/session.service';
import {catchError, EMPTY, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {PingService} from '../services/ping.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private session: SessionService,
    private router: Router,
    private pingService: PingService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status == 0) {
          if (req.url.includes('/ping')) {
            return EMPTY;
          }
          console.log('Network Problem');
          this.pingService.ping();
        }
        if (err.status === 401) {
          this.session.clearToken();
          this.router.navigate(['/login']).then();
          return EMPTY;
        }
        if (err.status === 429) {
          console.log('Too many requests');
          window.alert(
            'From your network too many requests have been sent. For security reasons, your access has been temporarily blocked.'
          );
          return EMPTY;
        }
        return throwError(err);
      }),
    );
  }
}
