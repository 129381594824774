import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { CaptureConsole } from '@sentry/integrations';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';

let sentryEnvironment = 'production';
if (window.location.hostname === 'app.plantguardian.ch') {
  sentryEnvironment = 'production';
} else if (
  window.location.hostname.endsWith('vercel.app') ||
  window.location.hostname.endsWith('pages.dev')
) {
  sentryEnvironment = 'staging';
} else {
  sentryEnvironment = 'development';
}

Sentry.init({
  dsn: 'https://073d86b64c563f15a0dbb407a6db08ee@o4504549113135104.ingest.sentry.io/4506376188985344',
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay(),
    new CaptureConsole({
      levels: ['error', 'warn', 'log', 'debug', 'info'],
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: sentryEnvironment,
  enabled: sentryEnvironment !== 'development',
});
if (environment.production) {
  enableProdMode();
}
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then()
  .catch((err) => console.error(err));
