import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-manual',
  templateUrl: './manual.component.html',
  styleUrls: ['./manual.component.scss'],
})
export class ManualComponent implements OnInit {
  pdf: string = '/assets/manual/Anleitung_de.pdf';

  constructor(private translate: TranslateService) {
  }

  setPdf() {
    switch (this.translate.currentLang) {
      case 'en':
        this.pdf = '/assets/manual/Manual_en.pdf';
        break;
      case 'de':
        this.pdf = '/assets/manual/Anleitung_de.pdf';
        break;
      default:
        this.pdf = '/assets/manual/Anleitung_de.pdf';
    }
  }

  ngOnInit(): void {
    this.setPdf();
    this.translate.onLangChange.subscribe(() => {
      this.setPdf();
    });
  }
}
