import { Component, OnInit } from '@angular/core';
import { UserService } from './services/user.service';
import { PingService } from './services/ping.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './services/language.service';
import { preferredLocale } from 'preferred-locale';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  connected = true;

  constructor(
    private userService: UserService,
    private pingService: PingService,
    private translate: TranslateService,
    private languageService: LanguageService,
  ) {
    this.pingService.connectedSubject.subscribe((connected) => {
      this.connected = connected;
    });
  }

  preferedLocale(fallback: string, supportedLocales: string[]): string {
    const userLocales = [navigator.language];
    if (navigator.languages) {
      userLocales.push(...navigator.languages);
    }
    for (const locale of userLocales) {
      const parts = locale.split('-');
      for (let i = parts.length; i > 0; i--) {
        const candidate = parts.slice(0, i).join('-');
        if (supportedLocales.includes(candidate)) {
          return candidate;
        }
      }
    }
    return fallback;
  }

  async ngOnInit() {
    this.userService.getUser();
    this.translate.addLangs(this.languageService.languages.map((l) => l.code));
    const supportedLocales = this.translate.getLangs();
    const fallback = 'en';
    const preferred = this.preferedLocale(fallback, supportedLocales);
    this.translate.setDefaultLang('de');
    this.translate.use(preferred);
  }
}
