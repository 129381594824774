<div class="center-box">
  <div *ngIf="connected">
    <router-outlet></router-outlet>
  </div>
  <div *ngIf="!connected">
    <app-banner [hideNav]="true"></app-banner>
    <app-offline></app-offline>
  </div>
  <app-download-banner></app-download-banner>
</div>
