import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LogoService {
  constructor() {}

  getLogoPath() {
    if (this.isChristmasSeason()) {
      return 'assets/images/logo_christmas.png';
    } else {
      return 'assets/images/logo.png';
    }
  }

  isChristmasSeason() {
    const today = new Date();
    const month = today.getMonth();
    const day = today.getDate();
    return (month === 11 && day >= 1) || (month === 0 && day <= 6);
  }
}
