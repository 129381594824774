import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgOptimizedImage } from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { HomeComponent } from './components/home/home.component';
import { AuthInterceptor } from './http-interceptors/auth-interceptor';
import { ErrorInterceptor } from './http-interceptors/error-interceptor';
import { DevicesComponent } from './components/devices/devices.component';
import { DeviceComponent } from './components/device/device.component';
import { DeviceAdderComponent } from './components/device-adder/device-adder.component';
import { BannerComponent } from './components/banner/banner.component';
import { DeviceDetailsComponent } from './components/device-details/device-details.component';
import { OfflineComponent } from './components/offline/offline.component';
import { MoistureChartComponent } from './components/moisture-chart/moisture-chart.component';
import { PotsizeComponent } from './components/potsize/potsize.component';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { DownloadBannerComponent } from './components/download-banner/download-banner.component';
import { allIcons, NgxBootstrapIconsModule } from 'ngx-bootstrap-icons';
import { MoistureThresholdComponent } from './components/moisture-threshold/moisture-threshold.component';
import { ManualComponent } from './components/manual/manual.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { WaterNowComponent } from './components/water-now/water-now.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    DevicesComponent,
    DeviceComponent,
    DeviceAdderComponent,
    BannerComponent,
    DeviceDetailsComponent,
    OfflineComponent,
    MoistureChartComponent,
    PotsizeComponent,
    DownloadBannerComponent,
    MoistureThresholdComponent,
    ManualComponent,
    WaterNowComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgOptimizedImage,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgxBootstrapIconsModule.pick(allIcons),
    NgxExtendedPdfViewerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) =>
          new TranslateHttpLoader(http, './assets/i18n/', '.json'),
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
