export class DeviceConfig {
  wateringInterval: number = 86400 * 6;
  wetThreshold: number = 1100;

  constructor(
    wateringInterval: number = 86400 * 6,
    wetThreshold: number = 1100,
  ) {
    this.wateringInterval = wateringInterval;
    this.wetThreshold = wetThreshold;
  }
}
