import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PingService {
  connected = true;
  connectedSubject = new BehaviorSubject<boolean>(true);

  constructor(private http: HttpClient) {
    setInterval(() => {
      if (!this.connected) {
        this.ping();
      }
    }, 30 * 1000);
  }

  ping(): void {
    this.http
      .get(environment.apiBaseUrl + '/ping')
      .pipe(
        catchError((err) => {
          this.connected = false;
          this.connectedSubject.next(false);
          console.log('Ping failed');
          return throwError(err);
        })
      )
      .subscribe(() => {
        console.log('Ping successful');
        if (!this.connected) {
          window.location.reload();
        }
        this.connected = true;
        this.connectedSubject.next(true);
      });
  }
}
