<nav class="nav">
  <div class="nav-items">
    <a routerLink="/" class="nav-link" *ngIf="!hideNav && !isHome">{{
      "banner.home" | translate
    }}</a>
    <button class="nav-link" *ngIf="!hideNav && isHome" routerLink="/manual">
      {{ "banner.manual" | translate }}
    </button>
  </div>
  <div class="nav-items logo-container">
    <a routerLink="/">
      <img
        [ngSrc]="logoPath"
        alt="logo"
        class="logo"
        width="500"
        height="500"
        priority
      />
    </a>
  </div>
  <div class="nav-items">
    <button class="nav-link" *ngIf="!hideNav" (click)="logout()">
      {{ "banner.logout" | translate }}
    </button>
  </div>
</nav>
