<h2>{{ "device.potsize.title" | translate }}</h2>
<p>{{ "device.potsize.subtitle" | translate }}</p>
<div class="selector-buttons">
  <button
    (click)="onPotSizeChange(PotSize.SMALL)"
    [class.active]="potSize === PotSize.SMALL"
  >
    {{ "device.potsize.small" | translate }}
    <span class="info">{{ "device.potsize.smallInfo" | translate }}</span>
  </button>
  <button
    (click)="onPotSizeChange(PotSize.MEDIUM)"
    [class.active]="potSize === PotSize.MEDIUM"
  >
    {{ "device.potsize.medium" | translate }}
    <span class="info">{{ "device.potsize.mediumInfo" | translate }}</span>
  </button>
  <button
    (click)="onPotSizeChange(PotSize.LARGE)"
    [class.active]="potSize === PotSize.LARGE"
  >
    {{ "device.potsize.large" | translate }}
    <span class="info">{{ "device.potsize.largeInfo" | translate }}</span>
  </button>
</div>
