import { Component, Input } from '@angular/core';
import { Device } from '../../models/Device';
import { DeviceService } from '../../services/device.service';

@Component({
  selector: 'app-water-now',
  templateUrl: './water-now.component.html',
  styleUrl: './water-now.component.scss',
})
export class WaterNowComponent {
  @Input() device: Device | undefined;
  waterNowSuccess = false;
  waterNowPossible = false;
  isWateringNow = false;
  offline = false;

  constructor(private deviceService: DeviceService) {
    this.deviceService.waterNowSuccessSubject.subscribe((waterNowSuccess) => {
      this.waterNowSuccess = waterNowSuccess;
    });
    setInterval(() => {
      if (this.device) {
        if (this.device.datapoints && this.device.datapoints.length > 0) {
          const lastDataPoint =
            this.device.datapoints[this.device.datapoints.length - 1];
          this.waterNowPossible =
            (lastDataPoint.data.moisture ?? 4096) >
            this.device.config.wetThreshold;
          this.isWateringNow =
            (lastDataPoint.data.currentWateringAmount ?? 0) > 0;
          this.offline =
            this.device.lastSeen <
            new Date(new Date().getTime() - 1000 * 60 * 60 * 24);
        }
      }
    }, 500);
  }

  onWater() {
    if (!this.device) return;
    this.deviceService.waterNow(this.device.mac);
  }
}
