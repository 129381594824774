<app-banner></app-banner>
<ngx-extended-pdf-viewer
  [src]="pdf"
  [height]="'auto'"
  [zoom]="'page-width'"
  [minZoom]="0.5"
  [textLayer]="true"
  [showHandToolButton]="false"
  [showPropertiesButton]="false"
  [showSidebarButton]="false"
  [showFindButton]="false"
  [showPrintButton]="false"
  [showZoomButtons]="false"
  [showPagingButtons]="false"
  [showPresentationModeButton]="false"
  [showOpenFileButton]="false"
  [showDownloadButton]="true"
  [showToolbar]="false"
>
</ngx-extended-pdf-viewer>
