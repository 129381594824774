import { Injectable } from '@angular/core';

export interface Language {
  code: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  languages: Language[] = [
    { code: 'en', name: 'English' },
    { code: 'de', name: 'Deutsch (German)' },
  ];

  constructor() {}
}
