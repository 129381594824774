import { Component, Input } from '@angular/core';
import { UserService } from '../../services/user.service';
import { LogoService } from '../../services/logo.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {
  @Input() hideNav: boolean = false;
  logoPath: string;
  isHome = false;
  isLogin = false;

  constructor(
    private userService: UserService,
    private logoService: LogoService,
  ) {
    this.logoPath = logoService.getLogoPath();
    setInterval(() => {
      this.logoPath = logoService.getLogoPath();
    }, 60 * 1000);
    if (window.location.pathname === '/') {
      this.isHome = true;
      this.isLogin = false;
    } else if (window.location.pathname === '/login') {
      this.isLogin = true;
      this.isHome = false;
    }
  }

  logout() {
    this.userService.logout();
  }
}
