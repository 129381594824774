<h2>{{ "device.threshold.title" | translate }}</h2>
<p>{{ "device.threshold.subtitle" | translate }}</p>
<div class="selector-buttons moisture">
  <button
    (click)="onMoistureThresholdChange(MoistureThreshold.VERY_DRY)"
    [class.active]="moistureThreshold === MoistureThreshold.VERY_DRY"
  >
    <img
      ngSrc="/assets/images/moisture/1.png"
      alt="1"
      class="option"
      width="2480"
      height="3508"
    />
    <p>{{ "device.threshold.veryDry" | translate }}</p>
  </button>
  <button
    (click)="onMoistureThresholdChange(MoistureThreshold.DRY)"
    [class.active]="moistureThreshold === MoistureThreshold.DRY"
  >
    <img
      ngSrc="/assets/images/moisture/2.png"
      alt="2"
      class="option"
      width="2480"
      height="3508"
    />
    <p>{{ "device.threshold.dry" | translate }}</p>
  </button>
  <button
    (click)="onMoistureThresholdChange(MoistureThreshold.NORMAL)"
    [class.active]="moistureThreshold === MoistureThreshold.NORMAL"
  >
    <img
      ngSrc="/assets/images/moisture/3.png"
      alt="3"
      class="option"
      width="2480"
      height="3508"
    />
    <p>{{ "device.threshold.normal" | translate }}</p>
  </button>
  <button
    (click)="onMoistureThresholdChange(MoistureThreshold.WET)"
    [class.active]="moistureThreshold === MoistureThreshold.WET"
  >
    <img
      ngSrc="/assets/images/moisture/4.png"
      alt="4"
      class="option"
      width="2480"
      height="3508"
    />
    <p>{{ "device.threshold.wet" | translate }}</p>
  </button>
  <button
    (click)="onMoistureThresholdChange(MoistureThreshold.VERY_WET)"
    [class.active]="moistureThreshold === MoistureThreshold.VERY_WET"
  >
    <img
      ngSrc="/assets/images/moisture/5.png"
      alt="5"
      class="option"
      width="2480"
      height="3508"
    />
    <p>{{ "device.threshold.veryWet" | translate }}</p>
  </button>
</div>
<p class="warning" *ngIf="moistureThreshold !== MoistureThreshold.NORMAL">
  {{ "device.threshold.warning" | translate }}
</p>
