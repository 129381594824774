<div class="card card-green">
  <div class="card-title">
    {{ device.description ? device.description : device.name }}
    <span *ngIf="device.lastSeen < twelveHoursAgo" class="red">{{
      "device.offline" | translate
    }}</span>
  </div>
  <div class="card-body">
    <p class="light">
      {{ device.mac.slice(0, 2) + device.mac.slice(3, 5) }}
      {{ device.mac.slice(6, 8) + device.mac.slice(9, 11) }}
      {{ device.mac.slice(12, 14) + device.mac.slice(15, 17) }}
    </p>
  </div>
  <div class="card-right">
    <button class="button" (click)="editDevice(device)">
      {{ "device.manage" | translate }}
    </button>
  </div>
</div>
