import { Component } from '@angular/core';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  loggedIn = false;

  constructor(private userService: UserService) {
    this.userService.loggedInSubject.subscribe((loggedIn) => {
      this.loggedIn = loggedIn;
    });
  }
}
